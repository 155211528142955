<template>
  <div class="fixed top-0 left-0 z-10 w-full bg-white">
    <!--/app-header 網站版頭-->
    <header class="app-header md:relative">
      <div class="header-con md:px-4">
        <button id="nav_menu" class="btn-square-rounded hasGrayBg md:hidden" @click="toggleMenu">
          <i class="menu">menu</i>
        </button>
        <h1 class="branding">
          <router-link :to="{ name: 'home' }">
            揪美美 ‧ Chill May May
         </router-link>
        </h1>
        <div v-if="!isLogin" class="w-5 h-5 md:hidden"></div>
        <div v-if="isLogin & (user.status == 1)" class="cart md:hidden">
          <button @click="gotoCart" v-if="cartQuantity > 0" class="cart__circle">{{ cartQuantity }}</button>
          <button id="nav_cart" class="btn-square-rounded" @click="gotoCart">
            <i class="cart">cart</i>
          </button>
        </div>
        <div v-if="isLogin & (user.status !== 1)" style="width:36px"><!--為了flex版面所以要留一個36px寬度--></div>
        <!-- PC -->
        <nav class="nav-main cart hidden md:flex" v-if="isLogin">
          <ul>
            <!-- <li>
                  <router-link :to="{name: 'dashboard'}" :class="{active: routerName === 'dashboard'}">
                    <a-icon type="home" />季度表現與回饋
                  </router-link>
                </li> -->
            <li v-if="user.status == 1">
                <router-link :to="{ name: 'products', query: { selectedKey: 'all' }}" :class="{ active: routerName === 'products' & routerQuery.selectedKey == 'all' }" class="flex"> <a-icon type="shopping" />選購商品</router-link>
            </li>
            <li v-if="user.status !== -1">
                <router-link :to="{ name: 'products', query: { selectedKey: 'hot' }}" :class="{ active: routerName === 'products' & routerQuery.selectedKey == 'hot' }" class="flex"> <a-icon type="shopping" />本期推薦</router-link>
            </li>
            <!-- <li v-if="user.status !== -1">
              <router-link :to="{ name: 'addresses' }" :class="{ active: routerName === 'addresses' }"> <a-icon type="pushpin" />寄送地址</router-link>
            </li> -->
            <li v-if="user.status !== -1">
              <router-link :to="{ name: 'personal' }" :class="{ active: routerName === 'personal' }" class="flex"> <a-icon type="user" />個人資料</router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'changePassword' }" :class="{ active: routerName === 'changePassword' }"> <a-icon type="key" />修改密碼</router-link>
            </li> -->
            <li v-if="user.status == 1">
              <router-link :to="{ name: 'orders' }" :class="{ active: routerName === 'orders' }" class="flex"> <a-icon type="dollar" />購物記錄</router-link>
            </li>
            <li v-if="user.status !== -1">
              <router-link :to="{ name: 'qa' }" :class="{ active: routerName === 'qa' }" class="flex"> <a-icon type="question-circle" />Q&A</router-link>
            </li>
            <li v-if="user.status == 1">
              <router-link :to="{ name: 'cart' }" :class="{ active: routerName === 'cart' }" class="flex items-center">
                <a-icon type="shopping-cart" />購物車
                <label v-if="cartQuantity > 0" class="cart__circle relative top-0 right-0 ml-1">{{ cartQuantity }}</label>
             </router-link>
            </li>
            <li>
              <a @click="handleLogout" class="pr-0 flex" > <a-icon type="logout" />登出</a>
            </li>
          </ul>
        </nav>
        <nav class="nav-main cart hidden md:flex" v-if="!isLogin">
          <ul>
            <li>
              <router-link :to="{ name: 'terms' }" :class="{ active: routerName === 'terms' }"> <a-icon type="user" />註冊</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'login' }" :class="{ active: routerName === 'login' }"> <a-icon type="login" />登入</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <!-- lb-menu 網站導覽列-->
    <article class="lightbox lb-menu" :style="{ display: isOpen ? 'block' : 'none' }">
      <div class="lightbox-wrap" :class="isOpen ? '_open' : '_close'">
        <nav class="nav">
          <ul v-if="isLogin">
            <!-- mobile -->
            <li v-if="user.status !== -1">
              <div class="menu-dashboard" @click="toPage('dashboard')"><i>dashboard</i><span>季度表現與回饋</span></div>
            </li>
            <li v-if="user.status == 1">
              <div class="menu-shopping" @click="toProduct('/products','all')"><i>shopping</i><span>選購商品</span></div>
            </li>
            <li v-if="user.status !== -1">
              <div class="menu-shopping" @click="toProduct('/products','hot')"><i>shopping</i><span>本期推薦</span></div>
            </li>
            <li v-if="user.status == 1">
              <div class="menu-cart" @click="toPage('cart')"><i>cart</i><span>購物車</span></div>
            </li>
            <li v-if="user.status == 1">
              <div class="menu-orders" @click="toPage('orders')"><i>orders</i><span>購物紀錄</span></div>
            </li>
            <li>
              <div class="menu-personal" @click="toPage('personal')"><i>user info</i><span>個人資料</span></div>
            </li>
            <!-- <li>
              <div class="menu-change-password" @click="toPage('changePassword')"><i>user info</i><span>修改密碼</span></div>
            </li> -->
            <li v-if="user.status !== -1">
              <div class="menu-qa" @click="toPage('qa')"><i>qa</i><span>Q&A</span></div>
            </li>
            <!-- <li v-if="user.status !== -1">
              <div class="menu-address" @click="toPage('addresses')"><i>address</i><span>寄送地址</span></div>
            </li> -->
          </ul>
          <ul v-if="!isLogin">
            <li>
              <div class="menu-register" @click="toPage('terms')"><i>register</i><span>註冊</span></div>
            </li>
            <li>
              <div class="menu-login" @click="toPage('login')"><i>login</i><span>登入</span></div>
            </li>
          </ul>
          <section v-if="isLogin" class="nav-btn">
            <button id="nav_logout" data-cy="logout" class="btn-basic btn-block btn-logout" @click="handleLogout">登出</button>
          </section>
        </nav>
        <button id="nav_close" class="btn-square-rounded btn-close" @click="toggleMenu"><i>close</i></button>
      </div>
      <div class="lightbox-backdrop" @click="toggleMenu"></div>
    </article>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import bodyScrollLock from '@/utils/bodyScrollLock.js' //modal開啟時鎖住body

export default {
  name: 'TheHeader',
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    ...mapActions(['logout']),
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
    toPage(name) {
      bodyScrollLock.disable()
      this.isOpen = false
      if (this.routerName === name) {
        return
      }
      this.$router.push({ name })
    },
    toProduct(name,key) {
      bodyScrollLock.disable()
      this.isOpen = false
      this.$router.push({ path:name, query:{ selectedKey:key }})
    },
    gotoCart() {
      this.$router.push('/cart')
    },
    handleLogout() {
      bodyScrollLock.disable()
      this.logout()
    }
  },
  computed: {
    ...mapGetters(['user', 'cartQuantity', 'isLogin']),
    routerName() {
      return this.$route.name
    },
    routerQuery(){
       return this.$route.query 
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value == true) {
          //防止手機背景滑動
          bodyScrollLock.enable()
        } else {
          bodyScrollLock.disable()
        }
      }
    }
  }
}
</script>
<style lang="postcss">
.cart__circle {
  @apply z-10;
  @apply text-sm;
  @apply text-white;
  @apply bg-pink-salmon;
  @apply right-1;
  @apply top-2;
  @apply absolute;
  @apply rounded-full;
  @apply h-5;
  @apply w-5;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}
</style>
