<template>
  <div class="app-wrapper">
    <the-header />
    <router-view></router-view>
    <footer class="mt-10 block"><the-footer /></footer>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'Home',
  components: { TheHeader, TheFooter },
}
</script>
